.service-card .ant-card-meta-title {
  white-space: unset;
}

.service-card {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  height: 100%;
}
.services-text .ant-card-meta-title {
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3c1a5b;
  margin-right: 6rem;
}
.ant-card-meta-title::after {
  content: "";
  height: 3px;
  width: 30%;
  background: #3c1a5b;
  position: absolute;
  left: calc(50% - 50%);
  bottom: -9px;
}
.services-text .ant-card-meta-description {
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
}
.service-card .card-footer {
  margin-top: auto;
}
