.testimonial-carousel-row {
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  padding: 8rem 5rem;
  height: 36rem;
}

.testimonial .slick-dots-bottom li {
  margin-top: 2rem !important;
}
.testimonial .slick-slide {
  padding: 10px;
}

.testimonial p {
  text-align: left;
}

.testimonial .slick-slide img {
  margin-bottom: 0.8rem;
}

.testimonial h3,
h4 {
  text-align: center;
}

.testimonial .ant-divider-vertical {
  height: 100%;
}

.testimonial .ant-card-bordered {
  border: none;
}

.slick-center {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  transform: scale(1.25);
  z-index: 2;
}

/* .slick-slide {
  margin: 0 27px;
} */
/* the parent */
.slick-list {
  margin: 0 0px;
  padding: 0rem !important;
}

.testimonial-divider {
  margin: 0px 0 8rem 0 !important;
}
