.quality-card {
  margin-top: 40px;
}

.row-quality {
  margin-bottom: 0px;
  padding-bottom: 100px;
}
.quality-card .ant-card {
  height: 100%;
  box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.half-underline {
  color: #3c1a5b;
  margin-bottom: 2rem;
  position: relative;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}
.no-underline {
  color: #3c1a5b;
  position: relative;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.half-underline::after {
  content: "";
  height: 3px;
  width: 30%;
  background: #3c1a5b;
  position: absolute;
  left: calc(50% - 50%);
  bottom: -10.5px;
}
.quality-card p {
  font-family: Montserrat;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
}

.quality-card .ant-card-body {
  padding: 3rem;
}
