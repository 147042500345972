.section-header {
  font-size: 4rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
  word-wrap: normal;
}

.section-para {
  color: #888888;
  margin-bottom: 6rem;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
}
