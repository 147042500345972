@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
.card {
  /* zoom: 1; */
  text-align: center;
  box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.16),
    0 0.2rem 1rem 0 rgba(0, 0, 0, 0);
}

.card .ant-card-body {
  padding: 5px;
}

.countries-img {
  height: 100px;
}

.countries-text .ant-card-meta-title {
  font-size: 2rem;
  font-weight: 400;
  margin: 6px 0px;
  color: #444444;
}

.highlight-card {
  margin-top: 40px;
}

.row-highlight {
  margin-bottom: 0px;
  padding-bottom: 100px;
}
.highlight-card .ant-card {
  height: 100%;
  border: 3px solid #3c1a5b;
  border-radius: 0px;
  background-color: #3c1a5b;
}
.highlight-card h2 {
  color: #ffffff;
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
}
.highlight-card p {
  color: #ffffff;
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
}

.highlight-card img {
  margin-bottom: 2rem;
  width: 6rem;
  height: 6rem;
}
.highlight-card .ant-card-body {
  padding: 1.2rem;
}

.quality-card {
  margin-top: 40px;
}

.row-quality {
  margin-bottom: 0px;
  padding-bottom: 100px;
}
.quality-card .ant-card {
  height: 100%;
  box-shadow: 1px 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.half-underline {
  color: #3c1a5b;
  margin-bottom: 2rem;
  position: relative;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}
.no-underline {
  color: #3c1a5b;
  position: relative;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.half-underline::after {
  content: "";
  height: 3px;
  width: 30%;
  background: #3c1a5b;
  position: absolute;
  left: calc(50% - 50%);
  bottom: -10.5px;
}
.quality-card p {
  font-family: Montserrat;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
}

.quality-card .ant-card-body {
  padding: 3rem;
}

.section-header {
  font-size: 4rem;
  color: #1c1c1c;
  font-weight: bold;
  margin: 0rem 0rem 3rem 0rem;
  line-height: 6rem;
  word-wrap: normal;
}

.section-para {
  color: #888888;
  margin-bottom: 6rem;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
}

.service-card .ant-card-meta-title {
  white-space: unset;
}

.service-card {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  height: 100%;
}
.services-text .ant-card-meta-title {
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3c1a5b;
  margin-right: 6rem;
}
.ant-card-meta-title::after {
  content: "";
  height: 3px;
  width: 30%;
  background: #3c1a5b;
  position: absolute;
  left: calc(50% - 50%);
  bottom: -9px;
}
.services-text .ant-card-meta-description {
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
}
.service-card .card-footer {
  margin-top: auto;
}

.testimonial-carousel-row {
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
  padding: 8rem 5rem;
  height: 36rem;
}

.testimonial .slick-dots-bottom li {
  margin-top: 2rem !important;
}
.testimonial .slick-slide {
  padding: 10px;
}

.testimonial p {
  text-align: left;
}

.testimonial .slick-slide img {
  margin-bottom: 0.8rem;
}

.testimonial h3,
h4 {
  text-align: center;
}

.testimonial .ant-divider-vertical {
  height: 100%;
}

.testimonial .ant-card-bordered {
  border: none;
}

.slick-center {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  z-index: 2;
}

/* .slick-slide {
  margin: 0 27px;
} */
/* the parent */
.slick-list {
  margin: 0 0px;
  padding: 0rem !important;
}

.testimonial-divider {
  margin: 0px 0 8rem 0 !important;
}

.registration-form-tag.ant-tag-checkable {
  border-color: #3c1a5b;
  border-radius: 25px;
  font-size: 13px;
  color: #3c1a5b;
  padding: 2px 10px;
  margin-top: 0.5rem;
}

.registration-page-background {
  padding: 0px;
  margin: 0px;
}

.registration-ant-form {
  padding: 1rem 2rem 2rem 2rem;
}
.registration-ant-form .ant-form-explain {
  font-size: 1rem;
}
@media only screen and (max-width: 600px) {
  .registration-ant-form {
    padding: 3rem 3rem 3rem 3rem;
  }
}
.register-form .ant-input {
  border-radius: 2.25rem !important;
  max-width: 254px;
  background-color: #f0f2f7;
}
.register-input {
  border-radius: 23px !important;
  border: 0.1rem solid #f0f2f7 !important;
}
.register-input:hover {
  border-radius: 23px !important;
  border: 0.1rem solid #3c1a5b !important;
}
.register-select .ant-select-selection {
  border-radius: 23px !important;
  border: 0.1rem solid #f0f2f7 !important;
  background-color: #f0f2f7;
}
.register-select .ant-select-selection:hover {
  border-radius: 23px !important;
  border: 0.1rem solid #3c1a5b !important;
  background-color: #f0f2f7;
}

@media only screen and (max-width: 600px) {
  .register-form-column {
    padding: 1rem !important;
  }
}

.register-form-column {
  padding: 9.6rem 0 4rem;
}

.register-text {
  /* background-color: #3c1a5b;*/
  align-items: center;
  /*clip-path: polygon(0 0, 100% 0, 100% 90%, 76% 100%, 0% 82%);*/
}

@media only screen and (max-width: 600px) {
  .register-text {
    -webkit-clip-path: unset !important;
            clip-path: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .register-text__glob-header-img {
    display: none;
  }
}

.register-text {
  background-image: url(/static/media/header.bfce17bf.png);

  /* Full height */
  min-height: 80vh;
  top: 0;
  left: 0;
  min-width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.register-text p {
  padding: 2rem 0;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #3c1a5b;
}

.register-text .ant-form label {
  font-size: 1.4rem !important;
  color: #3c1a5b;
  font-weight: 600;
}

.register-text .ant-form-item-label {
  line-height: 16px;
  padding: 0rem;
}

.register-checkbox span {
  font-size: 1.2rem !important;
  font-weight: normal;
  color: #818080;
}
.ant-form-item {
  margin-bottom: 8px !important;
}

.register-form {
  text-align: center;
  border: none;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);

  padding: 0 !important;

  background-color: #ffffff !important;
  border-radius: 0.5rem;
  max-width: 294px;
}

.register-form .ant-tag-checkable-checked {
  background-color: #3c1a5b;
  color: white !important;
}

.register-form .ant-btn-primary {
  background-color: #3c1a5b;
  border: 0.2rem solid #3c1a5b;
  border-radius: 23px !important;
  height: 3rem;
}

@-webkit-keyframes moveUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.footer-component {
  grid-column: -1;
  display: grid;
}

.footer-border {
  grid-column: -1;
  background-color: #3c1a5b;
  grid-row: 0.5;
  display: grid;
  height: 10rem;
}

.view-btn {
  grid-row: 0.5;
  grid-column: -1;
  justify-self: center;
  align-items: center;
  border: none;
  padding: 1rem;
  height: 5rem;
  width: 10rem;
  border-top-left-radius: 12rem;
  border-top-right-radius: 12rem;
  color: white;
  background-color: #3c1a5b;
  align-self: end;
}

.view-btn-2 {
  background-color: blue !important;
}

.fixed {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .footer {
    display: none;
  }
}

.footer {
  grid-column: -1;
  background-color: #176299;
}

.footer__content {
  padding: 5rem 3.5rem 1rem 3.5rem;
}

.footer__content-1 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 4rem;
}

.footer__image {
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 3rem;
}

.footer__image--icon {
  /* font-size: 2rem; */
  /* color: white; */
  /* cursor: pointer; */
  width: 2rem;
  height: 2rem;
}

.footer__bottom {
  margin-top: 5rem;
  grid-column: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__border {
  margin-top: 2rem;
  height: 0.1rem;
  width: 100%;
  background-color: white;
  margin-bottom: 1.2rem;
}

.footer__view-btn {
  font-size: 2rem;
}

.column-1__text {
  font-size: 1.8rem;
  color: white;
  margin-left: 1rem;
  font-weight: 500;
  text-align: left;
  text-align: initial;
}

.column-1__border {
  margin-top: 0.5rem;
  margin-left: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.column-1__border--1 {
  height: 0.2rem;
  background-color: white;
}

.column-1__content {
  margin-top: 3rem;
}

.row {
  display: grid;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.row__image {
  display: flex;
}

.row__image--icon {
  font-size: 2rem;
  color: white;
}

.row__para {
  color: white;
}

.footer-2 {
  grid-column: -1;
  background-color: blue;
}

.footer-2__text {
  color: white;
}

.footer-2__content {
  padding: 6rem 18rem 5rem 18rem;
  display: grid;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  grid-row-gap: 3rem;
}

.footer-2__row {
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 1rem;
}

.footer-2__row--links {
  display: flex;
  flex-wrap: wrap;
}

.footer-2__row--name {
  margin-right: 2rem;
}

.footer-2__row--para {
  color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
}

.footer-2__row--para::after {
  content: " ";
  display: block;
  height: 1.2rem;
  width: 0.1rem;
  background-color: white;
  margin: 0 2rem;
}

.footer-2__row--border {
  height: 1.5rem;
  background-color: white;
  width: 0.1rem;
  justify-self: center;
  align-self: center;
}

@media only screen and (min-width: 600px) {
  .mobile-footer {
    display: none;
  }
}

.mobile-footer {
  grid-column: -1;
  background-color: #134a7a;
}

.mobile-footer__content {
  padding: 2rem;
}

.mobile-footer__row--link {
  margin-top: 1rem;
}

.mobile-footer__row--text {
  color: white;
}

.mobile-footer__row--para {
  color: white;
}

.mobile-footer__row--para::after {
  margin-right: 1rem;
}

.mobile-footer__image {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(3, -webkit-min-content);
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 3rem;
}

.mobile-footer__image--icon {
  font-size: 2rem;
  color: white;
}

.terms-condition-heading {
  color: rgb(23, 98, 153);
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.heading-para {
  padding: 5rem 11rem;
  color: rgb(112, 112, 112);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

@media only screen and (max-width: 600px) {
  .heading-para {
    padding: 5rem 3rem;
  }
}

.paragraph-cell {
  padding: 0rem 20rem;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .paragraph-cell {
    padding: 0rem 3rem;
  }
}


.section-heading {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #176299;
  margin-bottom: 2rem;
}

.question-heading {
  margin-top: 2rem;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #176299;
}

.para {
  margin: 1rem 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.list-style {
  list-style-type: disc;
  list-style-position: inside;
  margin: 1rem 0 1rem 1rem;
}

.nested-list {
  list-style-type: circle;
  list-style-position: inside;
  margin: 1rem 0 1rem 3rem;
}
html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0rem;
  padding: 0rem;
}

h1 {
  font-size: 50px;
}

.section-start-end {
  padding: 7rem 0rem 10rem 0rem;
}
.section-start-no-end {
  padding: 7rem 0rem 0rem 0rem;
}
.footer-curve {
  background-color: white;
  border-bottom-left-radius: 50%;
  height: 80px;
  border-bottom-right-radius: 50%;
}
.bg-color-blue {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3c1a58), to(#3d3874));
  background-image: linear-gradient(to bottom, #3c1a58, #3d3874);
}

.bg-color-white {
  background-color: #ffffff;
}

.text-color-white {
  color: white !important;
}

.text-color-off-white {
  color: #dedede !important;
}

.testimonial-section-start {
  padding: 0rem 0rem 10rem 0rem;
}

.header0 .home-page {
  padding: 0 24px 0 40px !important;
}

.home-page-wrapper {
  background-color: white !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
}

body {
  word-wrap: break-word;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}
ul,
ol {
  list-style: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.header0 {
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu > .ant-menu {
  line-height: 62px;
  background: transparent;
  color: #3c1a5b;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
}
.header0-menu > .ant-menu a {
  color: #3c1a5b;
}
.header0-menu > .ant-menu a:hover {
  color: #3c1a59;
}
.header0 .ant-menu-item-selected a {
  color: #3c1a59;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0 .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0 .header0-menu li {
    padding: 0 24px;
  }
  .header0 .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0 .header0-menu .ant-menu-submenu .ant-menu-sub {
    padding: 0 24px;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #888888;
    margin-top: 4px;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
    background: #fff;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .ant-menu a {
    color: #3c1a5b;
  }
  .header0 .ant-menu a:hover {
    color: #3c1a5b;
  }
  .header0 .ant-menu .ant-menu-item-selected a {
    color: white;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #3c1a5b;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  line-height: 40px;
  font-size: 16px;
  height: 40px;
  -webkit-transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px #3c1a59;
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #3c1a5b;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.footer1-wrapper {
  background: #fff;
  overflow: hidden;
  position: relative;
  min-height: 400px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .footer1 .block {
  padding: 0 32px;
}
.footer1-wrapper .footer1 .block .logo {
  max-width: 180px;
}
.footer1-wrapper .footer1 .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .footer1 .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .footer1 .block > div {
  line-height: 24px;
}
.footer1-wrapper .footer1 .block > div a {
  color: #999;
}
.footer1-wrapper .footer1 .block > div a:hover {
  color: #3c1a59;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 100px;
  text-align: right;
  line-height: 80px;
  float: right;
}
@media screen and (max-width: 767px) {
  .footer1 {
    height: 550px;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
.card {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.15);
}
.card__bg-image {
  display: inline-block;
  object-fit: cover;
  width: 100%;
  height: 175px;
}
.card__logo {
  top: -60px;
}
.card__logo-image {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border: 0.5px solid #e9e9e9;
}
.ant-card-meta-description {
  line-height: 3ex;
  height: 15ex;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.u-center-text {
  text-align: center;
}
.u-margin-bottom-small {
  margin-bottom: 2rem;
}
.u-margin-bottom-medium {
  margin-bottom: 5rem;
}
.u-margin-top-small {
  margin-top: 2rem;
}
.heading-primary {
  color: #fff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  margin-bottom: 2rem;
}
.heading-primary--main {
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
}
.heading-secondary {
  font-size: 2rem;
  font-weight: 500;
  display: inline-block;
}
.ant-card-actions {
  background: #3db389;
  padding: 0px;
  border-radius: 0px 0px 1px 1px;
}
.ant-card-actions > li {
  margin: 0px;
}
.ant-card-actions > li > span {
  padding: 10px;
  width: 100%;
}
.selector {
  position: absolute;
  display: inline;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70%;
}

