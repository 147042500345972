.highlight-card {
  margin-top: 40px;
}

.row-highlight {
  margin-bottom: 0px;
  padding-bottom: 100px;
}
.highlight-card .ant-card {
  height: 100%;
  border: 3px solid #3c1a5b;
  border-radius: 0px;
  background-color: #3c1a5b;
}
.highlight-card h2 {
  color: #ffffff;
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
}
.highlight-card p {
  color: #ffffff;
  margin-bottom: 2rem;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
}

.highlight-card img {
  margin-bottom: 2rem;
  width: 6rem;
  height: 6rem;
}
.highlight-card .ant-card-body {
  padding: 1.2rem;
}
