.card {
  /* zoom: 1; */
  text-align: center;
  box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.16),
    0 0.2rem 1rem 0 rgba(0, 0, 0, 0);
}

.card .ant-card-body {
  padding: 5px;
}

.countries-img {
  height: 100px;
}

.countries-text .ant-card-meta-title {
  font-size: 2rem;
  font-weight: 400;
  margin: 6px 0px;
  color: #444444;
}
