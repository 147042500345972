.registration-form-tag.ant-tag-checkable {
  border-color: #3c1a5b;
  border-radius: 25px;
  font-size: 13px;
  color: #3c1a5b;
  padding: 2px 10px;
  margin-top: 0.5rem;
}

.registration-page-background {
  padding: 0px;
  margin: 0px;
}

.registration-ant-form {
  padding: 1rem 2rem 2rem 2rem;
}
.registration-ant-form .ant-form-explain {
  font-size: 1rem;
}
@media only screen and (max-width: 600px) {
  .registration-ant-form {
    padding: 3rem 3rem 3rem 3rem;
  }
}
.register-form .ant-input {
  border-radius: 2.25rem !important;
  max-width: 254px;
  background-color: #f0f2f7;
}
.register-input {
  border-radius: 23px !important;
  border: 0.1rem solid #f0f2f7 !important;
}
.register-input:hover {
  border-radius: 23px !important;
  border: 0.1rem solid #3c1a5b !important;
}
.register-select .ant-select-selection {
  border-radius: 23px !important;
  border: 0.1rem solid #f0f2f7 !important;
  background-color: #f0f2f7;
}
.register-select .ant-select-selection:hover {
  border-radius: 23px !important;
  border: 0.1rem solid #3c1a5b !important;
  background-color: #f0f2f7;
}

@media only screen and (max-width: 600px) {
  .register-form-column {
    padding: 1rem !important;
  }
}

.register-form-column {
  padding: 9.6rem 0 4rem;
}

.register-text {
  /* background-color: #3c1a5b;*/
  align-items: center;
  /*clip-path: polygon(0 0, 100% 0, 100% 90%, 76% 100%, 0% 82%);*/
}

@media only screen and (max-width: 600px) {
  .register-text {
    clip-path: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .register-text__glob-header-img {
    display: none;
  }
}

.register-text {
  background-image: url("../images/header.png");

  /* Full height */
  min-height: 80vh;
  top: 0;
  left: 0;
  min-width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.register-text p {
  padding: 2rem 0;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #3c1a5b;
}

.register-text .ant-form label {
  font-size: 1.4rem !important;
  color: #3c1a5b;
  font-weight: 600;
}

.register-text .ant-form-item-label {
  line-height: 16px;
  padding: 0rem;
}

.register-checkbox span {
  font-size: 1.2rem !important;
  font-weight: normal;
  color: #818080;
}
.ant-form-item {
  margin-bottom: 8px !important;
}

.register-form {
  text-align: center;
  border: none;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);

  padding: 0 !important;

  background-color: #ffffff !important;
  border-radius: 0.5rem;
  max-width: 294px;
}

.register-form .ant-tag-checkable-checked {
  background-color: #3c1a5b;
  color: white !important;
}

.register-form .ant-btn-primary {
  background-color: #3c1a5b;
  border: 0.2rem solid #3c1a5b;
  border-radius: 23px !important;
  height: 3rem;
}
